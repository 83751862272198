import { MenuesComponent } from 'components/Root/Header';
import React, { memo } from 'react'
import { Link } from 'react-router-dom';

const HomeHeader: React.FC = () => {
    return (<>
        <div className="th-menu-wrapper">
            <div className="th-menu-area text-center">
                <button className="th-menu-toggle">
                    <i className="fal fa-times"></i>
                </button>
                <div className="mobile-logo">
                    <Link to="/"><img src="/theme/assets/img/logo.png" alt="Taxiar" /></Link>
                </div>
                <div className="th-mobile-menu">
                    <MenuesComponent />
                </div>
            </div>
        </div>
        <div className="sidemenu-wrapper d-none d-lg-block">
            <div className="sidemenu-content bg-title">
                <button className="closeButton sideMenuCls">
                    <i className="far fa-times"></i>
                </button>
                <div className="widget footer-widget">
                    <h3 className="widget_title">About Company</h3>
                    <div className="th-widget-about">
                        <p className="footer-text">We provide 24/7 Transportation and VIP Airport Ground Service, we have Professional & Courteous Drivers, getting you to your destination on time.</p>
                        <h4 className="footer-info-title">WE ARE AVAILABLE</h4>
                        <p className="footer-text">provide 24/7</p>
                        <Link to={"/contact"} className="th-btn style3"><span className="btn-text">Contact Us</span></Link>
                    </div>
                </div>


            </div>
        </div>
        <header className="th-header header-layout3">
            <div className="header-top">
                <div className="container">
                    <div className="row gx-0 justify-content-center justify-content-md-between align-items-center">
                        <div className="col-auto">
                            <div className="top-left">
                                <p className="header-notice">Welcome to RV Limousine Service</p>
                            </div>
                        </div>
                        <div className="col-auto d-none d-md-block">
                            <div className="top-right">
                                <div className="row gx-0 align-items-center justify-content-between">
                                    <div className="col-auto">
                                        <div className="header-links">
                                            <ul>
                                                <li className="d-none d-lg-inline-block">
                                                    <i className="fal fa-phone"></i>
                                                    <a href="tel:+66929096446" target="new">+6692 909 6446</a>
                                                </li>
                                                <li className="d-none d-xxl-inline-block">
                                                    <i className="fal fa-location-dot"></i>
                                                    118/100 Pradya BisHome Lat Krabang
                                                </li>
                                                <li>
                                                    <i className="fal fa-envelope"></i>
                                                    <a href="mailto:reservations@rvlimousineservice.com" target="new">reservations@rvlimousineservice.com</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-wrapper">
                <div className="container">
                    <div className="row gx-0 justify-content-between">
                        <div className="col-auto">
                            <div className="header-logo">
                                <Link to={"/"}><img src="/theme/assets/img/logo.png" alt="Taxiar" /></Link>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="menu-area">
                                <div className="row gx-0 align-items-center justify-content-between">
                                    <div className="col-auto">
                                        <nav className="main-menu d-none d-lg-inline-block">
                                            <MenuesComponent />
                                        </nav>
                                        <button type="button" className="th-menu-toggle d-inline-block d-lg-none">
                                            <i className="far fa-bars"></i>
                                        </button>
                                    </div>
                                    <div className="col-auto d-none d-xxl-block">
                                        <div className="header-button">
                                            {/* <button type="button" className="icon-style2 searchBoxToggler">
                                                <i className="far fa-search"></i>
                                            </button> */}
                                            <a href="#" className="simple-icon sideMenuToggler">
                                                <i className="fa-solid fa-grid"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="th-hero-wrapper hero-slider-3 th-carousel" data-slide-show="1" data-md-slide-show="1" data-fade="true" data-dots="true" data-xl-dots="true" data-ml-dots="true" data-lg-dots="true">
            <div className="th-hero-slide">
                <div className="th-hero-bg" data-bg-src="/theme/assets/img/bg/hero_bg_3_1.jpg">
                    <img src="/theme/assets/img/bg/hero_overlay_3.png" alt="RV Limousine" />
                </div>
                <div className="container">
                    <div className="hero-style2">
                        <span className="hero-subtitle" data-ani="slideindown" data-ani-delay="0.7s">24/7 Online Car Booking Service</span>
                        <h1 className="hero-title" data-ani="slideindown" data-ani-delay="0.4s">Who we are at </h1>
                        <h1 className="hero-title" data-ani="slideindown" data-ani-delay="0.1s">RV Limousine?</h1>
                        <p className="hero-text" data-ani="slideinup" data-ani-delay="0.1s">We provide 24/7 Transportation and VIP Airport Ground Service, we have Professional & Courteous Drivers, getting you to your destination on time.</p>
                        <div className="btn-group" data-ani="slideinup" data-ani-delay="0.5s">
                            <Link className="th-btn style3" to={`/services?flag=booking`}>Book Now</Link>
                            <Link className="th-btn style2" to={`/vehicles`}>Serche Vehicle</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="th-hero-slide">
                <div className="th-hero-bg" data-bg-src="/theme/assets/img/bg/hero_bg_3_2.jpg">
                    <img src="/theme/assets/img/bg/hero_overlay_3.png" alt="RV Limousine" />
                </div>
                <div className="container">
                    <div className="hero-style2">
                        <span className="hero-subtitle" data-ani="slideindown" data-ani-delay="0.7s">24/7 Online Car Booking Service</span>
                        <h1 className="hero-title" data-ani="slideindown" data-ani-delay="0.4s">Who we are at </h1>
                        <h1 className="hero-title" data-ani="slideindown" data-ani-delay="0.1s">RV Limousine?</h1>
                        <p className="hero-text" data-ani="slideinup" data-ani-delay="0.1s">We provide 24/7 Transportation and VIP Airport Ground Service, we have Professional & Courteous Drivers, getting you to your destination on time.</p>
                        <div className="btn-group" data-ani="slideinup" data-ani-delay="0.5s">
                            <Link className="th-btn style3" to={`/services?flag=booking`}>Book Now</Link>
                            <Link className="th-btn style2" to={`/vehicles`}>Serche Vehicle</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div></>)
}

export default memo(HomeHeader);