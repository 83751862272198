import { DateUtil } from "@standard/utils";
import { SUBSERVICE_TYPE, SERVICE_TYPE } from "data/constants";
import Joi from "joi";
import JoiDate from "@joi/date";

const JoiWithDate = Joi.extend(JoiDate);

export default Joi.object({
    telephone: Joi.string().required().messages({
        'any.required': `{{#label}} is not allowed to be empty`
    }).label('Phone Number'),
    name: Joi.string().required().label('Your Name'),
    email: Joi.string().required().email({ tlds: { allow: false } }).label('Email'),
    weChatId: Joi.string().optional().allow(''),
    whatAppId: Joi.string().optional().allow(''),
    lineId: Joi.string().optional().allow(''),
    telephoneCountry: Joi.string().required().allow(''),
    serviceType: Joi.string().required().label("Service"),
    subserviceType: Joi.string().required().label("Subservice"),
    flightNo: Joi.string().label("Flight No").when("subserviceType", { is: SUBSERVICE_TYPE.LIMOUSINE_TRANSFER, then: Joi.required(), otherwise: Joi.optional().allow('') }),
    hours: Joi.number().label("Number of hours").when("serviceType", { is: SERVICE_TYPE.DISPOSAL, then: Joi.required(), otherwise: Joi.optional().allow('') }).when("subserviceType", { is: SUBSERVICE_TYPE.DISPOSAL_WITHIN, then: Joi.number().min(6) }).when("subserviceType", { is: SUBSERVICE_TYPE.DISPOSAL_OUTSIDE, then: Joi.number().min(10) }),
    numberOfPassenger: Joi.number().required().label("Number of passenger").max(Joi.ref('maxPassengers')).messages({
        'number.max': `{{#label}} must be less than or equal to {{maxPassengers}} for your chosen car`
    }),
    numberOfluggage: Joi.number().required().label("Number of luggage").max(Joi.ref('luggageAllowance')).messages({
        'number.max': `{{#label}} must be less than or equal to {luggageAllowance} for your chosen car`
    }),
    pickupDate: JoiWithDate.date().format('YY/MM/DD').utc().required().label("Service Date"),
    pickupTime: Joi.string().required().label("Service Time"),
    itinerary: Joi.string().label("Itinerary Plan").when("serviceType", { is: SERVICE_TYPE.DISPOSAL, then: Joi.required(), otherwise: Joi.optional().allow('') }),
    maxPassengers: Joi.number(),
    luggageAllowance: Joi.number(),
}).custom((value, helpers) => {
    if (value.pickupDate && value.pickupTime) {
        const today = new Date();

        const bookingDate = new Date(value.pickupDate.getTime());
        const [hour, minute] = value.pickupTime.split(":")
        bookingDate.setHours(hour);
        bookingDate.setMinutes(minute);

        const hours = DateUtil.dateDiff(today, bookingDate!, "hours");

        if (hours < 0) return helpers.message({ "custom": `"Service date" and "service time" can not be in the pass` });
        else if (hours < 12) return helpers.message({ "custom": `If your booking is less than 12 hours please contact +6692 909 6446` });
    }

    return value;
}).options({ abortEarly: false, allowUnknown: true })