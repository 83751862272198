import React, { useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "components/Root";
import { HelperUtil } from "@standard/utils";
import DataForm from "components/DataForm";
import BoxContainer from "components/BoxContainer";
import subserviceSchema from "./subservice.schema";
import { Link, redirect, useNavigate, useParams } from 'react-router-dom';
import { ServiceType, SubserviceType } from 'types/service.type';
import { selectSubService } from 'features/booking/bookingSlice';
import { IConfigSubservice, IConfigServices } from 'types/configService.type';
import { ServiceData } from 'data';
import GoogleMap from 'components/GoogleMap';
import { SERVICE_TYPE } from "data/constants";
import { LocationType } from 'types/booking.type';
import { store } from 'store';
import DataFormErrors from "components/DataForm/DataFormErrors";
import PopupImages from "components/PopupImages";
const $ = window.$;

const configServices = ServiceData as IConfigServices[];

type ServiceParams = {
    serviceType: ServiceType,
    subserviceType: SubserviceType
}

type GoogleMapConfigType = {
    enable: boolean;
    isSinglePlace: boolean;
}

export default () => {
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { serviceType, subserviceType } = useParams<ServiceParams>();

    const googleMapConfig: GoogleMapConfigType = useMemo(() => {
        return {
            enable: [SERVICE_TYPE.LIMOUSINE, SERVICE_TYPE.DISPOSAL].includes(serviceType!),
            isSinglePlace: serviceType === SERVICE_TYPE.DISPOSAL,
        }
    }, [serviceType, subserviceType])

    const subservice: IConfigSubservice = useMemo(() => {
        const configService = configServices.find(f => f.key === serviceType);
        if (configService) {
            const configSubservice = configService.subservices.find(f => f.key === subserviceType);
            if (configSubservice) return configSubservice;
        }

        return {
            title: "",
            description: "",
            assets: [],
        }
    }, [serviceType, subserviceType]);

    const { setTitle } = useContext(ThemeContext);

    useEffect(() => {
        setTitle(subservice.title);
    }, [JSON.stringify(subservice)]);

    const onGoogleMapChanged = (value: any) => {

    }

    useEffect(() => {
        if (redirect === true) navigate(`/vehicles`);
    }, [redirect])

    const onSubmit = (e: any) => {
        e.preventDefault();

        const dispatchData: any = {
            serviceType: serviceType!,
            subserviceType: subserviceType!,
            startDestination: e.target.startDestination.value,
            startDestinationLocation: JSON.parse(e.target.startDestinationLocation.value!) as LocationType,
            distance: e.target.distance.value!,
            endDestination: "",
            endDestinationLocation: null,
        }

        if (serviceType === SERVICE_TYPE.LIMOUSINE) {
            dispatchData.endDestination = e.target.endDestination.value;
            dispatchData.endDestinationLocation = JSON.parse(e.target.endDestinationLocation.value!) as LocationType;
        }

        const { error } = subserviceSchema.validate(dispatchData);

        if (error) {
            return setErrors(error.details.map(d => d.message));
        }

        store.dispatch(selectSubService(dispatchData));
        setRedirect(true);
    }

    return (
        <BoxContainer>
            <PopupImages values={subservice.assets} />
            <div className="container">
                <div style={{ "marginBottom": "50px" }}>
                    {HelperUtil.htmlParse(subservice.description ?? "")}
                </div>
            </div>
            {googleMapConfig.enable &&
                <DataForm onSubmit={onSubmit}>
                    <div className="booking-title-area">
                        <h4 className="booking-title">Make Your Booking Today</h4>
                        <p className="booking-desc">
                            It is a long established fact that a reader will be distracted by
                            the rng is that it has distribution of letters to using content
                            here making it look like readable.
                        </p>
                    </div>
                    <div className="row">
                        <DataFormErrors errors={errors} />
                        <GoogleMap isSinglePlace={googleMapConfig.isSinglePlace} onChange={onGoogleMapChanged} />
                        <div className="form-btn col-12">
                            <button className="th-btn fw-btn" type='submit'>
                                Book Now <i className="fa-regular fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </DataForm>}
            {googleMapConfig.enable === false && (<div className="form-btn col-12">
                <Link to={`/contact`} className="th-btn fw-btn">Book Now <i className="fa-regular fa-arrow-right"></i></Link>
            </div>)}

        </BoxContainer>
    );
};