import React, { memo } from 'react'
import { IBookingItem } from 'types/booking.type'
import { Link } from 'react-router-dom';
import { SERVICE_TYPE } from "data/constants";
import { DateUtil } from '@standard/utils';

function ContactInfo({ bookingInfo }: { bookingInfo: IBookingItem }) {

    return (
        <div className="col-lg-12">
            <h2 className="h4">Contact Info</h2>
            <div className="row">
                <div className="col-12 text-end"><Link to={`/booking`} className="shipping-calculator-button"><i className="fa fa-pencil"></i> Edit</Link></div>

                {bookingInfo.serviceType === SERVICE_TYPE.LIMOUSINE && <>
                    <div className="col-md-6 col-12 form-group">
                        <strong>Pickup:</strong> {bookingInfo.startDestination}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                        <strong>Drop off:</strong> {bookingInfo.endDestination}
                    </div>
                </>}
                {bookingInfo.serviceType === SERVICE_TYPE.DISPOSAL && <>
                    <div className="col-12 form-group">
                        <strong>Pickup:</strong> {bookingInfo.startDestination}
                    </div>
                </>}
                <div className="col-md-6 col-12 form-group">
                    <strong>Your Name:</strong> {bookingInfo.name}
                </div>
                <div className="col-md-6 col-12 form-group">
                    <strong>Phone Number:</strong> {bookingInfo.telephone}
                </div>
                <div className="col-md-6 col-12 form-group">
                    <strong>Email:</strong> {bookingInfo.email}
                </div>
                {bookingInfo.serviceType === SERVICE_TYPE.LIMOUSINE && <>
                    <div className="col-md-6 col-12 form-group">
                        <strong>Flight Number:</strong> {bookingInfo.flightNo}
                    </div>
                </>}
                {bookingInfo.serviceType === SERVICE_TYPE.DISPOSAL && <>
                    <div className="col-md-6 col-12 form-group">
                        <strong>Number of hours:</strong> {bookingInfo.hours}
                    </div>
                </>}
                <div className="col-12 form-group">
                    <strong>We Chat:</strong> {bookingInfo.weChatId}
                </div>
                <div className="col-12 form-group">
                    <strong>What App:</strong> {bookingInfo.whatAppId}
                </div>
                <div className="col-12 form-group">
                    <strong>Line:</strong> {bookingInfo.lineId}
                </div>
                <div className="col-md-6 col-12 form-group">
                    <strong>Number of passenger:</strong>{" "}
                    {bookingInfo.numberOfPassenger}
                </div>
                <div className="col-md-6 col-12 form-group">
                    <strong>Number of luggage:</strong> {bookingInfo.numberOfluggage}
                </div>
                <div className="col-md-6 col-12 form-group">
                    <strong>Service Date:</strong> {bookingInfo.pickupDate ? DateUtil.convertDateToString(bookingInfo.pickupDate) : ""}
                </div>
                <div className="col-md-6 col-12 form-group">
                    <strong>Service Time:</strong> {bookingInfo.pickupTime}
                </div>
                <div className="col-12 form-group">
                    <strong>Message:</strong> {bookingInfo.message}
                </div>
                {bookingInfo.serviceType === SERVICE_TYPE.DISPOSAL && <>
                    <div className="col-12 form-group">
                        <strong>Itinerary Plan:</strong> {bookingInfo.itinerary}
                    </div>
                </>}
            </div>
        </div>
    )
}

export default memo(ContactInfo)