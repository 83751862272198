import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { GoogleMap, LoadScript, Autocomplete, DirectionsService, DirectionsRenderer, Marker } from '@react-google-maps/api';
import { AlertUtil, NumberUtil } from '@standard/utils';

const libraries: any = ['places'];

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = { lat: 13.6923085, lng: 100.7481393 };


type onChangeType = { startDestinationLocation: string, endDestinationLocation: string, distance: number };
type GoogleMapPropType = {
  onChange: (value: onChangeType) => void;
  isSinglePlace: boolean;
}

type Place = {
  lat: number;
  lng: number;
}

type MarketContainerType = {
  source: Place | null;
  destination: Place | null;
  isSinglePlace: boolean;
}

const MarkerContainer = ({ source, isSinglePlace, destination }: MarketContainerType) => {
  if (isSinglePlace) {
    if (source) return <Marker position={source} />
  } else {
    if (source !== null && destination === null) return <Marker position={source} />
    if (destination !== null && source === null) return <Marker position={destination} />
  }

  return <></>
}


export default (props: GoogleMapPropType) => {
  const [map, setMap] = useState<any>(null);
  const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  const [distance, setDistance] = useState<number | null>(null);
  const [source, setSource] = useState<Place | null>(null);
  const [destination, setDestination] = useState<Place | null>(null);
  const autocompleteSourceRef = useRef<any>(null);
  const autocompleteDestRef = useRef<any>(null);
  const distanceKM = useMemo(() => distance ? NumberUtil.convertToMoney(distance / 1000) : null, [distance]);
  const startDestinationRef = useRef<any | null>(null);
  const endDestinationRef = useRef<any | null>(null);

  const onLoad = useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);


  const onLoadSource = (autocomplete: any) => {
    autocompleteSourceRef.current = autocomplete;
  };

  const onLoadDestination = (autocomplete: any) => {
    autocompleteDestRef.current = autocomplete;
  };

  const onPlaceChanged = (type: any) => {
    if (type === 'source') {
      const place = autocompleteSourceRef.current.getPlace();
      if (place.geometry === undefined) return setSource(null);

      setSource({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
    } else {
      const place = autocompleteDestRef.current.getPlace();
      if (place.geometry === undefined) return setDestination(null);

      setDestination({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
    }
  };

  const onMapClick = async (e: any, type: any) => {
    const { latLng } = e;
    const location: Place = {
      lat: latLng.lat(),
      lng: latLng.lng()
    };

    const placeInfo = await getPlaceInfo(location);
    if (type === 'source') {
      setSource(location);
      if (placeInfo) startDestinationRef!.current.value = placeInfo;
    } else {
      setDestination(location);
      if (placeInfo) endDestinationRef!.current.value = placeInfo;
    }
  };

  const getPlaceInfo = async (location: Place): Promise<string | null> => {
    return new Promise((resolve) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: new window.google.maps.LatLng(location.lat, location.lng) }, (results: any, status: any) => {
        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
          const place = results[0];
          if (place && place.formatted_address) return resolve(place.formatted_address);

          return resolve(null);
        }
      });
    });
  }

  const calculateRoute = () => {
    if (source && destination) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: source,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result: any, status: any) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
            setDistance(result!.routes[0].legs[0].distance!.value);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  };

  useEffect(() => {
    calculateRoute();
  }, [source, destination]);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!} libraries={libraries} language="en"
      region="US">
      <>
        <Autocomplete onLoad={onLoadSource} onPlaceChanged={() => onPlaceChanged('source')} className={`form-group col-sm-${props.isSinglePlace === false ? "6" : "12"}`}>
          <>
            <input
              type="text"
              className="form-control"
              name="startDestination"
              id="startDestination"
              placeholder="Search Pickup"
              ref={startDestinationRef}
            />
            <i className="fal fa-location-dot"></i>
          </>
        </Autocomplete>
        {props.isSinglePlace === false && <Autocomplete onLoad={onLoadDestination} onPlaceChanged={() => onPlaceChanged('destination')} className="form-group col-sm-6">
          <>
            <input
              type="text"
              className="form-control"
              name="endDestination"
              id="endDestination"
              placeholder="Search Dropoff"
              ref={endDestinationRef}
            />
            <i className="fal fa-location-dot"></i>
          </>
        </Autocomplete>}
        <div className='col-sm-12'>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={source || center}
            zoom={(source || destination) ? 12 : 10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={async (e) => {
              if (props.isSinglePlace === true) return await onMapClick(e, 'source');

              if (!source) await onMapClick(e, 'source');
              else await onMapClick(e, 'destination');
            }}
          >
            {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
            <MarkerContainer source={source} destination={destination} isSinglePlace={props.isSinglePlace} />
          </GoogleMap>
        </div>
        <input type="hidden" name="distance" value={distance ?? ""} />
        <input type="hidden" name="startDestinationLocation" value={JSON.stringify(source ?? {})} />
        <input type="hidden" name="endDestinationLocation" value={JSON.stringify(destination ?? {})} />
        <div style={{ marginTop: '10px' }}>
          {distanceKM && <p>Distance: {distanceKM} KM</p>}
        </div>
      </>
    </LoadScript>
  );
};
